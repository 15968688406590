import { createAction, handleActions } from 'redux-actions';
import createSaga, { createActionTypes} from '../../utils/Request/RequestCreateSaga';
import * as DASH_API from '../../api/DashApi';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { initialState } from '../initialState';

//액션 타입정의
const ACTION_RESET_FORM = 'dash/ACTION_RESET_FORM';
const ACTION_DASH_CHANGE_INFORMATION = 'dash/DASH_CHANGE_INFORMATION';
const [ACTION_M_BODY_COMP, ACTION_M_BODY_COMP_SUCCESS] = createActionTypes('dash/ACTION_M_BODY_COMP');
const [ACTION_M_SCATTER_PLOT, ACTION_M_SCATTER_PLOT_SUCCESS] = createActionTypes('dash/ACTION_M_SCATTER_PLOT');
const [ACTION_M_GOAL_ACHIEVE, ACTION_M_GOAL_ACHIEVE_SUCCESS] = createActionTypes('dash/ACTION_M_GOAL_ACHIEVE');
const [ACTION_TOTAL_USER_GENDER, ACTION_TOTAL_USER_GENDER_SUCCESS] = createActionTypes('dash/ACTION_TOTAL_USER_GENDER');
const [ACTION_TOTAL_AGE, ACTION_TOTAL_AGE_SUCCESS] = createActionTypes('dash/ACTION_TOTAL_AGE');
const [ACTION_TOTAL_WEIGHT, ACTION_TOTAL_WEIGHT_SUCCESS] = createActionTypes('dash/ACTION_WEIGHT');
const [ACTION_SCATTER_PLOT, ACTION_SCATTER_PLOT_SUCCESS] = createActionTypes('dash/ACTION_SCATTER_PLOT');
const [ACTION_BODY_COMP, ACTION_BODY_COMP_SUCCESS] = createActionTypes('dash/ACTION_BODY_COMP');
const [ACTION_GOAL_ACHIEVE, ACTION_GOAL_ACHIEVE_SUCCESS] = createActionTypes('dash/ACTION_GOAL_ACHIEVE');
const [ACTION_MISSION_RATE, ACTION_MISSION_RATE_SUCCESS] = createActionTypes('dash/ACTION_MISSION_RATE');
const [ACTION_MISSION_PERCENTAGE, ACTION_MISSION_PERCENTAGE_SUCCESS] = createActionTypes('dash/ACTION_MISSION_PERCENTAGE');
const [ACTION_MISSION_MEMBER_LIST, ACTION_MISSION_MEMBER_LIST_SUCCESS] = createActionTypes('dash/ACTION_MISSION_MEMBER_LIST');

//Saga 생성
const SAGA_TOTAL_USER_GENDER = createSaga(ACTION_TOTAL_USER_GENDER, DASH_API.getTotalUserGender);
const SAGA_TOTAL_AGE = createSaga(ACTION_TOTAL_AGE, DASH_API.getTotalAge);
const SAGA_TOTAL_WEIGHT = createSaga(ACTION_TOTAL_WEIGHT, DASH_API.getWeight);
const SAGA_SCATTER_PLOT = createSaga(ACTION_SCATTER_PLOT, DASH_API.getScatterPlot);
const SAGA_BODY_COMP = createSaga(ACTION_BODY_COMP, DASH_API.getBodyComp);
const SAGA_MISSION_RATE = createSaga(ACTION_MISSION_RATE, DASH_API.getMissionRate);
const SAGA_MISSION_PERCENTAGE = createSaga(ACTION_MISSION_PERCENTAGE, DASH_API.getMissionPer);
const SAGA_MISSION_MEMBER_LIST = createSaga(ACTION_MISSION_MEMBER_LIST, DASH_API.getMissionMemList);
const SAGA_GOAL_ACHIEVE = createSaga(ACTION_GOAL_ACHIEVE, DASH_API.getGoalAchieve);
const SAGA_M_BODY_COMP = createSaga(ACTION_M_BODY_COMP, DASH_API.getBodyComp);
const SAGA_M_SCATTER_PLOT = createSaga(ACTION_M_SCATTER_PLOT, DASH_API.getScatterPlot);
const SAGA_M_GOAL_ACHIEVE = createSaga(ACTION_M_GOAL_ACHIEVE, DASH_API.getGoalAchieve);

// Data 저장 액션함수
export const CHANGE_DASH_INFORMATION = createAction(
  ACTION_DASH_CHANGE_INFORMATION,
  ({ form, name, value }) => ({
  form, name, value,
}));
export const RESET_FORM = createAction(ACTION_RESET_FORM, form => form);
export const GET_TOTAL_USER_GENDER = createAction(ACTION_TOTAL_USER_GENDER, index => index);
export const GET_TOTAL_AGE = createAction(ACTION_TOTAL_AGE, index => index);
export const GET_TOTAL_WEIGHT = createAction(ACTION_TOTAL_WEIGHT, period => period);
export const GET_SCATTER_PLOT = createAction(ACTION_SCATTER_PLOT, period => period);
export const GET_BODY_COMP = createAction(ACTION_BODY_COMP,data => data);
export const GET_MISSION_RATE = createAction(ACTION_MISSION_RATE, index => index);
export const GET_MISSION_PERCENTAGE = createAction(ACTION_MISSION_PERCENTAGE, data => data);
export const GET_MISSION_MEMBER_LIST = createAction(ACTION_MISSION_MEMBER_LIST, data => data);
export const GET_GOAL_ACHIEVE = createAction(ACTION_GOAL_ACHIEVE,data => data);
export const GET_M_BODY_COMP = createAction(ACTION_M_BODY_COMP, data => data);
export const GET_M_SCATTER_PLOT = createAction(ACTION_M_SCATTER_PLOT, data => data);
export const GET_M_GOAL_ACHIEVE = createAction(ACTION_M_GOAL_ACHIEVE, data => data);

export function* dashSaga() {
  yield takeLatest(ACTION_TOTAL_USER_GENDER, SAGA_TOTAL_USER_GENDER);
  yield takeLatest(ACTION_TOTAL_AGE, SAGA_TOTAL_AGE);
  yield takeLatest(ACTION_TOTAL_WEIGHT, SAGA_TOTAL_WEIGHT);
  yield takeLatest(ACTION_SCATTER_PLOT, SAGA_SCATTER_PLOT);
  yield takeLatest(ACTION_BODY_COMP, SAGA_BODY_COMP);
  yield takeLatest(ACTION_GOAL_ACHIEVE, SAGA_GOAL_ACHIEVE);
  yield takeLatest(ACTION_MISSION_RATE, SAGA_MISSION_RATE);
  yield takeLatest(ACTION_MISSION_PERCENTAGE, SAGA_MISSION_PERCENTAGE);
  yield takeLatest(ACTION_MISSION_MEMBER_LIST, SAGA_MISSION_MEMBER_LIST);
  yield takeLatest(ACTION_M_BODY_COMP, SAGA_M_BODY_COMP);
  yield takeLatest(ACTION_M_SCATTER_PLOT, SAGA_M_SCATTER_PLOT);
  yield takeLatest(ACTION_M_GOAL_ACHIEVE, SAGA_M_GOAL_ACHIEVE);
}

export default handleActions( {
  [ACTION_DASH_CHANGE_INFORMATION]: (state, { payload: { form, name, value } }) =>
    produce(state, draft => {
      draft[form][name] = value;
    }),
  [ACTION_RESET_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ACTION_M_BODY_COMP_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    mBodyComp: data,
  }),
  [ACTION_M_SCATTER_PLOT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    mScatterPlot: data,
  }),
  [ACTION_M_GOAL_ACHIEVE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    mGoalAchieve: data,
  }),
  [ACTION_TOTAL_USER_GENDER_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    totalUserGender: data,
  }),
  [ACTION_TOTAL_AGE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    totalAge: data,
  }),
  [ACTION_TOTAL_WEIGHT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    totalWeight: data,
  }),
  [ACTION_SCATTER_PLOT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    scatterPlot: data,
  }),
  [ACTION_BODY_COMP_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bodyComp: data,
  }),
  [ACTION_GOAL_ACHIEVE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    goalAchieve: data,
  }),
  [ACTION_MISSION_RATE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    missionRate: data,
  }),
  [ACTION_MISSION_MEMBER_LIST_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    missionMemList: data,
  }),
  [ACTION_MISSION_PERCENTAGE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    missionPer: data,
  }),
}, initialState);