import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerTitle = styled.div`
  text-align: right;
  width: 100%;
  
  input {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border-radius: 5px;
    
    &::placeholder {
      font-size: 0.8rem;
    }
  }
`;

const DatePickerRangeTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  
  input {
    padding: 3px;
    font-size: 1rem;
    border: 1px solid #b1b1b1;
    outline: none;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    width: 150px;
    
    &::placeholder {
      font-size: 0.8rem;
    }
  }
  
  p {
    margin: 14px 0;
  }
`;

const Calendar = props => {
  return (
    <DatePickerTitle>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={props.selected}
        onChange={props.onChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
        includeDates={props.includeDates}
        placeholderText={props.placeholder}
        scrollableYearDropdown
        showYearDropdown
      />
    </DatePickerTitle>
  )
};

export const CalendarRange = props => {
  return (
    <>
      <DatePickerRangeTitle className={props.className}>
        <DatePicker
          selected={props.startDate}
          dateFormat="yyyy-MM-dd"
          onChange={data => props.onChange(data, 'S')}
          maxDate={props.endDate}
          selectsStart
        />
          <p>~</p>
        <DatePicker
          selected={props.endDate}
          dateFormat="yyyy-MM-dd"
          onChange={data => props.onChange(data, 'E')}
          minDate={props.startDate}
          // maxDate={props.maxDate}
          selectsEnd
        />
      </DatePickerRangeTitle>
    </>
  )
}

export default Calendar;