import styled from 'styled-components';
import React from 'react';

const ErrorMessage = styled.div`
  color: #fd4c4c;
  font-size: 13px;
`;

export const Error = props => {
  return (
    <ErrorMessage>
      {props.Text}
    </ErrorMessage>
  );
}