import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { CalendarRange } from '../Calendar';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHANGE_DASH_INFORMATION,
  GET_M_BODY_COMP,
  GET_M_GOAL_ACHIEVE,
  GET_M_SCATTER_PLOT, GET_MISSION_MEMBER_LIST, GET_MISSION_PERCENTAGE, RESET_FORM,
} from '../../../modules/manager/DashModules';
import { GET_MAX_DATE } from '../../../utils/UseFunc/UseStorage';
import { ChartLegendBodyComp, ChartLegendGoal, ChartLegendScatter } from '../ChartLegend';
import {
  CHART_MANAGER_BODY_COMP,
  CHART_MANAGER_GOAL_ACHIEVE, CHART_MANAGER_MISSION_DETAIL,
  CHART_MANAGER_SCATTER_PLOT,
} from '../../chart/MngChartList';
import i18next from 'i18next';

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  width: 1100px;
  height: ${props => props.height}px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 20px;
    animation: fade-in 0.4s ease 1;
  
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  img {
    min-width: 380px;
    max-width: 380px;
  }
`;

const DetailHeader = styled.div`
  border-bottom: 1px solid #cec9c9;
  padding-bottom: 10px;
  display: flex;
`;

const DetailBody = styled.div`
  table {
    tbody tr td {
      border: 0;
      border-bottom: 1px solid #ccc;
    }
  }
  
  .subGrid {
    thead tr th {
      background: #d2d2d2;
      border: 1px solid #969696;
      color: #000;
    }
  }
`;

const ModalDashDetail = props => {
  const getType = () => {
    switch (props.name) {
      case 'MngScatterPlot' :
        return {
          form: 'scatterPlotDte',
          sDate: scatterPlotDte.sDate,
          eDate: scatterPlotDte.eDate,
        };

      case 'UsrBodyComp' :
      case 'MngBodyComp' :
        return {
          form: 'bodyCompDte',
          sDate: bodyCompDte.sDate,
          eDate: bodyCompDte.eDate,
        };

      case 'UsrGoalAchieve' :
      case 'MngGoalAchieve' :
        return {
          form: 'goalAchieveDte',
          sDate: goalAchieveDte.sDate,
          eDate: goalAchieveDte.eDate,
        };

      case 'MngMission' :
        return {
          form: 'missionDte',
          sDate: missionDte.sDate,
          eDate: missionDte.eDate,
        };

      default :
        return '';
    }
  };

  const dispatch = useDispatch();
  const { bodyCompDte, scatterPlotDte, goalAchieveDte, missionDte, missionPer, mScatterPlot, mBodyComp, mGoalAchieve } = useSelector(({ dash }) => ({
    scatterPlotDte: dash.scatterPlotDte,
    bodyCompDte: dash.bodyCompDte,
    goalAchieveDte: dash.goalAchieveDte,
    missionDte: dash.missionDte,
    missionPer: dash.missionPer,
    mScatterPlot: dash.mScatterPlot,
    mBodyComp: dash.mBodyComp,
    mGoalAchieve: dash.mGoalAchieve,
  }));

  const onDateChange = (value, type) => {
    const name = type === 'S' ? 'sDate' : 'eDate';

    dispatch(
      CHANGE_DASH_INFORMATION({
        form: getType().form,
        name,
        value: Date.parse(value),
      }),
    );
  };

  useEffect(() => {
    dispatch(RESET_FORM('missionMemList'));
    dispatch(RESET_FORM('mBodyComp'));
    switch (props.name) {
      case 'MngScatterPlot' :
        if(scatterPlotDte) {
          const { type, sDate, eDate } = scatterPlotDte;

          dispatch(
            GET_M_SCATTER_PLOT({
              index: props.index,
              type, sDate, eDate
            })
          );
        }
        break;
      case 'UsrBodyComp' :
      case 'MngBodyComp' :
        if(bodyCompDte) {
          const { type, sDate, eDate } = bodyCompDte;

          dispatch(
            GET_M_BODY_COMP({
              index: props.index,
              type, sDate, eDate
            })
          );
        }
        break;
      case 'UsrGoalAchieve' :
      case 'MngGoalAchieve' :
        if(goalAchieveDte) {
          const { type, sDate, eDate } = goalAchieveDte;

          dispatch(
            GET_M_GOAL_ACHIEVE({
              index: props.index,
              type, sDate, eDate
            })
          );
        }
        break;
      case 'MngMission' :
        dispatch(GET_MISSION_PERCENTAGE(missionDte));
        dispatch(GET_MISSION_MEMBER_LIST(missionDte));
        break;
      default:
        break;
    }
  }, [dispatch, bodyCompDte, goalAchieveDte, scatterPlotDte, missionDte]);

  return (
    <>
      <ModalOverlay visible={props.visible}/>
      <ModalWrapper tabIndex="-1" visible={props.visible}>
        <ModalInner tabIndex="0" className="modal-inner" height={props.name === 'MngMission' ? 350 : 550}>
          {props.children}

          <DetailHeader>
            <div style={{ width: '65%' }}>
              {i18next.t(props.name)} <br/>
              <span style={{ fontSize: 12 }}>{i18next.t(props.name + 'Comment')}</span>
            </div>

            <div style={{ width: '35%' }}>
              <CalendarRange
                startDate={getType().sDate}
                endDate={getType().eDate}
                maxDate={GET_MAX_DATE(new Date(bodyCompDte.sDate))}
                onChange={onDateChange}
              />
            </div>
          </DetailHeader>

          <DetailBody>
            {
              props.name === 'MngScatterPlot'
                ? <ChartLegendScatter/>
                : (props.name === 'MngBodyComp' || props.name === 'UsrBodyComp')
                ? <ChartLegendBodyComp legendLength={3}/>
                : props.name === 'MngMission'
                  ? ''
                  : <ChartLegendGoal/>
            }


            <div style={{ height: props.name === 'MngMission' ? 200 : 350 }}>
              {props.name === 'MngScatterPlot' ?
                <CHART_MANAGER_SCATTER_PLOT
                  data={mScatterPlot}
                />
                :
                (props.name === 'MngBodyComp' || props.name === 'UsrBodyComp') ?
                  <CHART_MANAGER_BODY_COMP
                    data={mBodyComp}
                  />
                  :
                  props.name === 'MngMission' ?
                    <CHART_MANAGER_MISSION_DETAIL
                      data={missionPer}
                    />
                    :
                    <CHART_MANAGER_GOAL_ACHIEVE
                      data={mGoalAchieve}
                    />
              }
            </div>
          </DetailBody>
          <Button onClick={props.onClose} Text={i18next.t('ButtonClose')} className="mt-3 float-right"/>
        </ModalInner>
      </ModalWrapper>
    </>
  );
};


export default ModalDashDetail;