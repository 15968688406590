import React, { useState } from 'react';
import styled from 'styled-components';
import CardHeader from './CardHeader';
import ModalDashDetail from './modal/ModalDashDetail';

const MngCardForm = styled.div`
  width: 100%;
`;

const MngCardBody = styled.div`
  height: ${props => props.height === 'auto' ? 'auto' : '300px'};
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  margin-bottom: 1.5rem;
  padding: 15px;
  background-color: #FFFFFF;
  border-radius: 3px;
  position: relative;

  &.mb-mobile {
    margin-bottom: 0;
  }
`;

const Card = props => {
  const [visible, setVisible] = useState(false);

  const modalOpen = () => {
    setVisible(true);
  }

  const modalClose = () => {
    setVisible(false);
  }

  return (
    <MngCardForm width={props.width} height={props.height}>
      {visible &&
        <ModalDashDetail
          visible={visible}
          onClose={modalClose}
          name={props.filterName}
          onChange={props.onChange}
        />
      }

      <MngCardBody className={props.className} height={props.height}>
        {props.header &&
          <CardHeader
            header={props.header}
            filter={props.filter}
            filterName={props.filterName}
            filterType={props.filterType}
            onChange={props.onChange}
            onOpen={modalOpen}
            userInfoData={props.userInfoData}
          />
        }

        {props.children}
      </MngCardBody>
    </MngCardForm>
  );
};

export default Card;