import { call, put } from 'redux-saga/effects';
import { startLoading, finishLoading } from './RequestLoading';
import { callApi } from '../../api';
import { GET_COMPSEQ, GET_LANGUAGE_I18N, GET_MEMBERSEQ } from '../UseFunc/UseStorage';
import i18n from 'i18next';
import { resources } from '../../lib/lang/i18n';

export const createActionTypes = type => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return [type, SUCCESS, FAILURE]
};

export default function createSaga(type, request, status, loading) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  loading = loading === undefined;

  return function* (action) {
    check();

    if(loading)
      yield put(startLoading(type));

    try {
      const response = yield call(request, action.payload);
      yield put({
        type: SUCCESS,
        payload: status ? response.status : response.data,
      });
    } catch (e) {
      console.log(e);
      if(status) {
        yield put({
          type: SUCCESS,
          payload: e.response.status,
        });
      } else {
        yield put({
          type: FAILURE,
          payload: e,
          error: true,
        });
      }
    }

    if(loading)
      yield put(finishLoading(type));
  };
};

const check = () => {
  return callApi().get('/member/withdrawalCheck?memberSeq=' + GET_MEMBERSEQ() + '&compSeq=' + GET_COMPSEQ())
    .then(res => {
      const langCode = res.data.substr(0, 2).toUpperCase();
      let lang;

      switch (langCode) {
        case 'KO':
          lang = 'KR';
          break;
        case 'EN':
          lang = 'EN';
          break;
        default :
          lang = 'EN';
          break;
      }

      i18n.init({
        lng: lang.toUpperCase(),
        // lng: 'EN',
        debug: false,
        resources
      });
    })
    .catch(error => {
      try {
        const status = error.response.status;
        // 탈퇴 회원 && 존재하지않는 회원
        if(status === 401 || status === 404) {
          sessionStorage.clear();
          sessionStorage.setItem('withdrawal', true);
          window.location.reload();
        }
      } catch (e) {

      }
    });
}
