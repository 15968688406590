import React from 'react';
import styled from 'styled-components'
import i18next from 'i18next';
import { AiOutlineCheck, AiOutlineWarning } from 'react-icons/all';
import Calendar from '../../components/Calendar';
import Input from '../../components/Input';
import ModalForm from '../../components/modal/ModalForm';
import { Error } from '../../components/Error';
import { StyleButton } from '../../components/Button';
import Table from '../../components/Table';
import { AdminColor } from '../../../lib/styles/palette';

const IdCheckBox = styled.div`
  border: 1px solid #ccc;
  border-radius: 3px;
  
  input {
    width: 91%!important;
    outline: none;
    border: none!important;
    border-radius: 0;
  }
  
  svg {
    width: 20px;
    height: 20px;
  }
  
`;

const ModalAdminCrud = props => {
  const company = props.company;
  const onChangeNumber = e => {
    e.target.value = e.target.value.replace(/\D/,'');
    props.onChange(e);
  };

  return (
    <ModalForm
      show={props.show}
      onHide={props.onClose}
      header={`업체 ${props.company.TypeCheck ? '수정' : '등록'}`}
      background={AdminColor}
    >
      <Table>
        <tbody>
        <tr>
          <th>* 아이디</th>
          <td>
            <IdCheckBox>
              <Input
                name="CompId"
                onChange={props.onChange}
                value={company.CompId}
                readOnly={company.TypeCheck}
                max={40}
              />
            </IdCheckBox>
          </td>
        </tr>
        <tr>
          <th>* 업체명</th>
          <td>
            <Input
              name="CompName"
              onChange={props.onChange}
              value={company.CompName}
              max={50}
            />
          </td>
        </tr>
        <tr>
          <th>* 업체코드</th>
          <td>
            <Input
              name="CompCode"
              onChange={props.onChange}
              value={company.CompCode}
              max={10}
            />
          </td>
        </tr>
        <tr>
          <th>* 이메일</th>
          <td>
            <IdCheckBox>
              <Input
                name="CompEmail"
                onChange={props.onChange}
                value={company.CompEmail}
                max={40}
              />

              {
                props.emailCheck === 200
                  ? <AiOutlineCheck color={'#23af23'} /> :
                props.emailCheck === 405
                  ? <AiOutlineWarning color={'#e43747'} />
                  : ''
              }
            </IdCheckBox>

            {props.emailCheck === 405 &&
              <Error Text={i18next.t('SuccessEmail')}/>
            }
          </td>
        </tr>
        <tr>
          <th> 전화번호 </th>
          <td>
            <Input
              name="CompTel"
              onChange={onChangeNumber}
              value={company.CompTel}
              max={16}
            />
          </td>
        </tr>
        <tr>
          <th> 주소 </th>
          <td>
            <Input
              name="CompAdr"
              onChange={props.onChange}
              value={company.CompAdr}
              max={100}
            />
          </td>
        </tr>
        <tr>
          <th> 만료일자 </th>
          <td>
            <Calendar
              minDate={new Date()}
              selected={props.company.CompExpDte}
              onChange={date => props.onChange({
                target : {
                  name : 'CompExpDte',
                  value : Date.parse(date)
                }
              })}
            />
          </td>
        </tr>

        </tbody>
      </Table>

      <div className="my-2">
        <span style={{color: '#666', fontSize: 12}}>
          입력하신 이메일로 임시 비밀번호가 전송됩니다.
        </span>

        <Error Text={props.onError} />
      </div>

      <div className="mt-2 text-right">
        <StyleButton
          text={props.company.TypeCheck ? '수정' : '등록'}
          background={AdminColor}
          onClick={props.onSubmit}
        />
      </div>
    </ModalForm>
  )
}


export default ModalAdminCrud;