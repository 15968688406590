import React from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import { CheckEmpty } from '../../utils/UseFunc/UseFunction';
import Select from '../components/Select';
import Input from '../components/Input';
import { SVG_DELETE, SVG_DETAIL_BLANK, SVG_EDIT, SVG_RESET_PW } from '../components/Icon';
import { StyleButton } from '../components/Button';
import ModalAdminPwChange from './modal/ModalAdminPwChange';
import ModalAdminCrud from './modal/ModalAdminCrud';
import { AdminColor } from '../../lib/styles/palette';
import { useHistory } from 'react-router';

const CompanyForm = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  
  span {
    margin: 0;
  }
  
`;

const CompanyHeaderBtn = styled.div`
  display: flex;
  text-align: right;
  width: 100%;
  padding: 1rem 0;
  
  select {
    background-color: #FFF;
    background-position: 95%;
    margin-right: 1rem!important;
  }
  
  button {
    margin-right: 5px;
    margin-top: 0;
    font-weight: 300!important;
  }
  
  input::placeholder {
    font-size: 13px;
  }
`;

const AdminCompanyList = props => {
  const history = useHistory();
  const options = {
    defaultColDef: {
      resizable: true,
      sortable: true,
    },
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    /*onCellClicked(event) {
      const { path } = event.event;

      if (path[1].id === 'delete') {
        props.onDelete(event.data.index)
      }

      if (path[1].id === 'edit') {
        event.data.check = true;
        event.data.compSeq = event.data.index;

        props.openModal();
        props.onChangeForm(event.data);
      }

      // 업체 비밀번호 초기화 기능
      // 만들어두었지만 실제 사용하진 않음
      /!*if (path[1].id === 'resetPw') {
        props.onPwModalOpen(event.data.mSeq);
      }*!/

      if (path[1].id === 'detail') {
        history.push(`/admin/list/${event.data.index}`)
      }
    },*/
  };

  const onEditClick = event => {
    event.data.check = true;
    event.data.compSeq = event.data.index;

    props.openModal();
    props.onChangeForm(event.data);
  }

  const columnDefs = [
    {headerName: '업체코드', field : 'compCode', width: 100},
    {headerName: '업체명', field : 'compName', width: 240},
    {headerName: '마스터 아이디', field : 'id', width: 240},
    {headerName: '마스터 이메일', field : 'email', width: 320},
    {headerName: '등록일자', field : 'regDate', width: 130},
    {headerName: '만료일자', field : 'expDte', width: 130},
    {headerName: '등록회원 수', field : 'cntUser', width: 130},
    {headerName: '상태', field : 'status', width: 100,
      cellRenderer: params =>
        params.value === '사용'
          ? '<span class="chkIn"> <span class="chartDot chartDot_in" > </span> 사용 </span>'
          : '<span class="chkOut"> <span class="chartDot chartDot_out" > </span> 미사용 </span>'
    },
    {headerName: '버튼', field : '', width: 175, id : 'delete',
      cellRenderer: event => (
        <div>
          <span className="mr-2 chartSvg" id="detail" onClick={() => history.push(`/admin/list/${event.data.index}`)}>{SVG_DETAIL_BLANK}</span>
          <span className="mr-2 chartSvg" id="edit" onClick={() => onEditClick(event)}>{SVG_EDIT}</span>
          <span className="chartSvg" id="delete" onClick={() => props.onDelete(event.data.index)}>{SVG_DELETE}</span>
        </div>
      )
    },
  ];

  return (
    <CompanyForm>
      {props.onPwShow &&
        <ModalAdminPwChange
          show={props.onPwShow}
          pwInfo={props.pwInfo}
          error={props.onPwError}
          onCheck={props.onPwCheck}
          onCheckStatus={props.onPwCheckStatus}
          onClose={props.onPwModalClose}
          onChange={props.onPwChange}
          onSubmit={props.onPwSubmit}
      />
      }

      {props.onCrudShow &&
        <ModalAdminCrud
          show={props.onCrudShow}
          company={props.company}
          onChange={props.onChange}
          onClose={props.closeModal}
          onSubmit={props.onSubmit}
          reloadMember={props.reloadMember}
        />
      }

      <CompanyHeaderBtn>
        <div className="txtL" style={{width : '70%'}}>
          상태 :
          <Select
            className="w-25 mx-2"
            SelectType="company"
            onChange={props.onChangeList}
            name="type"
          />
          업체코드/명 :
          <Input
            placeHolder=""
            className="w-25 mx-2"
            onChange={props.onChangeList}
            name="codeName"
          />
        </div>

        <div style={{width : '30%'}}>
          <StyleButton
            text="업체 생성"
            onClick={props.openModal}
            className="w-25"
            background={AdminColor}
          />
        </div>
      </CompanyHeaderBtn>

      <div className="ag-theme-alpine" style={{height: '750px', width: '100%'}}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={CheckEmpty(props.companyList) ? [] : props.companyList}
          gridOptions={options}
        />
      </div>
    </CompanyForm>
  );
};

export default AdminCompanyList;