import React, { useEffect } from 'react';
import { callApi } from '../../api';
import { BIZ_IP, Login } from '../../utils/UseFunc/UseFunction';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { finishLoading, startLoading } from '../../utils/Request/RequestLoading';
import i18next from 'i18next';
import { SET_ALERT } from '../../modules/common/AlertModule';

/*
  apiToken[1] = access_token
  apiToken[2] = state
  apiToken[3] = token_type
  apiToken[4] = expires_in
*/

const AuthSnsLogin = props => {
  const apiToken = window.location.href.split('=');
  const token = apiToken[1].split('&')[0];
  const dispatch = useDispatch();
  let state;

  useEffect(() => {
    dispatch(startLoading());

    callApi().get(BIZ_IP + ':4000/getComp?token=' + token)
      .then( async res => {
        const id = res.data;

        state = await Login(id, null, 'N');

        state.type === 'U' ?
          props.history.push('/usr/info')
          : state.type === 'A' ?
          props.history.push('/company/create')
          : (state.type === 'M' || state.type === 'S') &&
          props.history.push('/mng/dashboard');

        if(state.error !== '') {
          dispatch(
            SET_ALERT({
              type: 'Warning',
              text: i18next.t('ErrorNonReg'),
              submit: {
                act: props.history.push('/'),
              },
            })
          );
        }
      });

    dispatch(finishLoading());
  }, [dispatch]);

  return '';
};

export default withRouter(AuthSnsLogin);