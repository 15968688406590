import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import { GetLang } from '../../utils/UseFunc/UseLang';
import downArrow from '../../lib/styles/img/icon/iconArrowDown.png';
import { useDispatch, useSelector } from 'react-redux';
import { GET_MANAGER } from '../../modules/manager/MemberModules';

const SelectForm = styled.select`
  width: 100%;
  height: 35px;
  border: 1px solid #d6d4d4;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 0.875rem;
  color: #000;
  text-indent: 5px;
  background: url(${downArrow}) no-repeat 98%;
  background-size: 20px;
  outline: none;
  cursor: pointer;
  
  // 기존 Select 화살표 삭제
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
  
  &:invalid {
    color: #a7a7a7;
  }
  option{
    color: #000;
  }
`;

const Select = props => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState();
  const { Manager } = useSelector(({ member }) => ({
    Manager: member.manager,
  }));

  useEffect(() => {
    switch (props.SelectType) {
      case 'Manager':
        dispatch(GET_MANAGER());
        break;
      default:
        break;
    }
  }, [dispatch]);

  const onChange = e => {
    setPeriod(e.target.value);

    if(props.SelectType === 'Mission') {
      setPeriod('');
    }

    switch (props.SelectType) {
      case 'BodyTrend':
      case 'deviceType':
        props.onChange(e);
        break;
      case 'country':
        props.onChange({
          target: {
            name: 'countryCode',
            value: e.target[e.target.selectedIndex].id,
          },
        });
        break;
      case 'Gender':
      case 'MemberType':
      case 'Manager':
      case 'company':
      case 'category':
        props.onChange({
          target: {
            name: props.name,
            value: e.target[e.target.selectedIndex].id,
          },
        });
        break;
      default :
        break;
    }
  };

  const Option = () => {
    switch (props.SelectType) {
      case 'Gender' :
        return (
          <>
            <option id="F">{i18next.t('GenderF')}</option>
            <option id="M">{i18next.t('GenderM')}</option>
          </>
        );

      case 'MemberType' :
        return (
          <>
            <option id="U">{i18next.t('MemType01')}</option>
            <option id="M" className={props.MemberType === 'M' ? 'Hide' : ''}>{i18next.t('MemType02')}</option>
          </>
        );

      case 'Manager' :
        if (Manager !== undefined) {
          return (
            <>
              <option id=""> - </option>
              {Manager.map(values => (
                <option key={values.memberSeq} id={values.memberSeq} selected={values.memberSeq === props.value && 'selected'}>
                  {values.name}
                </option>
              ))}
            </>
          );
        } else {
          return '';
        }

      case 'Notice1stCtg' :
        return (
          <>
            <option value={i18next.t('MngSctNot1st01')}>{i18next.t('MngSctNot1st01')}</option>
            <option value={i18next.t('MngSctNot1st02')}>{i18next.t('MngSctNot1st02')}</option>
          </>
        );

      case 'Notice2ndNtc' :
        return (
          <>
            <option>{i18next.t('MngSctNot1st01')}</option>
          </>
        );

      case 'Notice2stMis' :
        return (
          <>
            <option>{i18next.t('MngSctNot2ndMis01')}</option>
            <option>{i18next.t('MngSctNot2ndMis02')}</option>
          </>
        );

      case 'BodyTrend' :
        return (
          <>
            <option id="7 day">{i18next.t('MngDashCardSelect01')}</option>
            <option id="1 month">{i18next.t('MngDashCardSelect02')}</option>
            <option id="3 month">{i18next.t('MngDashCardSelect03')}</option>
            <option id="6 month">{i18next.t('MngDashCardSelect04')}</option>
            <option id="1 year">{i18next.t('MngDashCardSelect05')}</option>
          </>
        );

      case 'country' :
        return GetLang().map(values => (
          <option
            key={values.langCode}
            id={values.langCode}
            selected={values.langCode === props.value}
          >
            {values.langName}
          </option>
        ));

      case 'company' :
        return (
          <>
            <option id="">전체</option>
            <option id="Y">사용</option>
            <option id="N">미사용</option>
          </>
        );

      case 'deviceType' :
        return (
          <>
            <option id="">{i18next.t('OtherCtg07')}</option>
            <option id="SM">{i18next.t('device01')}</option>
            <option id="FT,FN">{i18next.t('device02')}</option>
            <option id="FL">{i18next.t('device03')}</option>
            <option id="FA">{i18next.t('device05')}</option>
          </>
        );

      case 'category' :
        if (props.Data !== undefined) {
          return props.Data.map(values => (
            <option key={values.catSeq} id={values.catSeq} >
              {values.title}
            </option>
          ));
        } else {
          return '';
        }

      default :
        return (
          <>
            <option>No Data</option>
          </>
        );
    }
  };

  return (
    <SelectForm
      onChange={onChange}
      value={period}
      name={props.name}
      className={props.className}
      required
      defaultValue={props.defaultValue}
    >
      {props.SelectType === 'category' &&
        <option
          value=""
          disabled
          selected
          hidden
        >
          {i18next.t('TempListHeader01')}
        </option>
      }

      <Option />
    </SelectForm>
  );
};

export default Select;