import {combineReducers} from 'redux';
import loading from '../utils/Request/RequestLoading';
import {all} from 'redux-saga/effects'
import auth, {authSaga} from './auth/AuthModules';
import user, { userSaga } from './user/UserModules';
import body, { bodySaga } from './user/BodyModules';
import dash, { dashSaga } from './manager/DashModules';
import member, { memberSaga } from './manager/MemberModules';
import message, { messageSaga } from './manager/MessageModules';
import mission, { missionSaga } from './manager/MissionModules';
import chat from './manager/ChatModule';
import alert from './common/AlertModule';

const rootReducer = combineReducers({
  auth,
  user,
  body,
  dash,
  member,
  message,
  mission,
  loading,
  alert,
  chat
});

export function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    bodySaga(),
    dashSaga(),
    memberSaga(),
    messageSaga(),
    missionSaga(),
  ]);
}

export default rootReducer;