import React from 'react';
import 'chartjs-plugin-annotation';
import {
  CHART_MANAGER_BODY_COMP, CHART_MANAGER_GOAL_ACHIEVE, CHART_MANAGER_MISSION, CHART_MANAGER_RANK_AVG,
  CHART_MANAGER_SCATTER_PLOT,
  CHART_MANAGER_TOTAL_AGE,
  CHART_MANAGER_TOTAL_USER_GENDER, CHART_MANAGER_TOTAL_WEIGHT,
} from './MngChartList';

const MngChartList = ({ Type, Data }) => {
  switch (Type) {
    case 'TotalUserAndGender':
      return (
        <CHART_MANAGER_TOTAL_USER_GENDER data={Data}/>
      );

    case 'TotalAge': {
      return (
        <CHART_MANAGER_TOTAL_AGE data={Data} />
      );
    }

    case 'Weight':
      return (
        <CHART_MANAGER_TOTAL_WEIGHT data={Data}/>
      );

    case 'ScatterPlot':
      return (
        <CHART_MANAGER_SCATTER_PLOT data={Data} />
      );

    case 'BodyComp':
      return (
        <CHART_MANAGER_BODY_COMP data={Data} />
      );

    case 'Mission':
      return (
        <CHART_MANAGER_MISSION data={Data} />
      );

    case 'GoalAchieve':
      return (
        <CHART_MANAGER_GOAL_ACHIEVE data={Data}/>
      );

    case 'MemberAverage':
      return (
        <CHART_MANAGER_RANK_AVG data={Data}/>
      );

    default :
      return null;
  }
};

export const MngChartView = ({ChartType, ChartData, Annotations, className}) => {
  return (
    <MngChartList
      className={'non-card ' + className}
      Type={ChartType}
      Data={ChartData}
      Annotations={Annotations}
    />
  );
};