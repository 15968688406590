import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import createSaga, { createActionTypes } from '../../utils/Request/RequestCreateSaga';
import * as userAPI from '../../api/UserApi';
import * as healthAPI from '../../api/HealthApi'
import { takeLatest } from 'redux-saga/effects';
import { GET_MAX_DATE, GET_START_DATE } from '../../utils/UseFunc/UseStorage';

const dateModule = {
  sDate : GET_START_DATE(new Date()),
  eDate : Date.parse(new Date()),
  maxDate : GET_MAX_DATE(new Date())
}

//액션 타입정의
const RESET_FORM = 'user/RESET_FORM';
const USER_CHANGE_INFORMATION = 'user/USER_CHANGE_INFORMATION';
const [GET_USER_INFO, GET_USER_INFO_SUCCESS] = createActionTypes('user/GET_USER_INFO');
const [USER_EDIT_INFORMATION, USER_EDIT_INFORMATION_SUCCESS] = createActionTypes('user/USER_EDIT_INFORMATION');
const [USER_EDIT_PASSWORD, USER_EDIT_PASSWORD_SUCCESS] = createActionTypes('user/USER_EDIT_PASSWORD');
const [GET_GOAL_ACHIEVE, GET_GOAL_ACHIEVE_SUCCESS] = createActionTypes('user/GET_GOAL_ACHIEVE');
const [GET_MISSION_RATE, GET_MISSION_RATE_SUCCESS] = createActionTypes('user/GET_MISSION_RATE');
const [GET_BODYCOMP, GET_BODYCOMP_SUCCESS] = createActionTypes('user/GET_BODYCOMP');
const [GET_BPM, GET_BPM_SUCCESS] = createActionTypes('user/GET_BPM');
const [GET_BPM_BAND, GET_BPM_BAND_SUCCESS] = createActionTypes('user/GET_BPM_BAND');
const [GET_RUN, GET_RUN_SUCCESS] = createActionTypes('user/GET_RUN');
const [GET_SLEEP, GET_SLEEP_SUCCESS] = createActionTypes('user/GET_SLEEP');
const [GET_SLEEP_PERIOD, GET_SLEEP_PERIOD_SUCCESS] = createActionTypes('user/GET_SLEEP_PERIOD');
const [GET_BLOODPRESSURE, GET_BLOODPRESSURE_SUCCESS] = createActionTypes('user/GET_BLOODPRESSURE');
const [GET_TEMP_BLOODPRESSURE_DIRECTINPUT, GET_TEMP_BLOODPRESSURE_DIRECTINPUT_SUCCESS] = createActionTypes('user/GET_TEMP_BLOODPRESSURE_DIRECTINPUT');
const [GET_BLOODPRESSURE_INPUT, GET_BLOODPRESSURE_INPUT_SUCCESS] = createActionTypes('user/GET_BLOODPRESSURE_INPUT');
const [GET_BLOODSUGAR_INPUT, GET_BLOODSUGAR_INPUT_SUCCESS] = createActionTypes('user/GET_BLOODSUGAR_INPUT');
const [GET_STRESS, GET_STRESS_SUCCESS] = createActionTypes('user/GET_STRESS');
const [GET_STRESS_BAND, GET_STRESS_BAND_SUCCESS] = createActionTypes('user/GET_STRESS_BAND');
const [GET_STEPS, GET_STEPS_SUCCESS] = createActionTypes('user/GET_STEPS');
const [GET_TEMPERATURE, GET_TEMPERATURE_SUCCESS] = createActionTypes('user/GET_TEMPERATURE');
const [GET_BAND_STATUS, GET_BAND_STATUS_SUCCESS] = createActionTypes('user/GET_BAND_STATUS');

//Saga 생성
const Saga_BodyComp = createSaga(GET_BODYCOMP, userAPI.getBodyComp);
const Saga_GetUserInfo = createSaga(GET_USER_INFO, userAPI.getUserInfo);
const Saga_GoalAchieve = createSaga(GET_GOAL_ACHIEVE, userAPI.getGoalAchieve)
const Saga_MissionRate = createSaga(GET_MISSION_RATE, userAPI.getMissionRate)
const Saga_EditInfo = createSaga(USER_EDIT_INFORMATION, userAPI.editInfo)
const Saga_EditPassword = createSaga(USER_EDIT_PASSWORD, userAPI.editInfo)
const Saga_Bpm = createSaga(GET_BPM, healthAPI.getHealth);
const Saga_Bpm_Band = createSaga(GET_BPM_BAND, healthAPI.getBpmBand);
const Saga_Run = createSaga(GET_RUN, healthAPI.getRun);
const Saga_Sleep = createSaga(GET_SLEEP, healthAPI.getSleep);
const Saga_Sleep_Period = createSaga(GET_SLEEP_PERIOD, healthAPI.getSleepPeriod);
const Saga_BloodPressure = createSaga(GET_BLOODPRESSURE, healthAPI.getBloodPressure);
const Saga_Temp_BloodPressure_DirectInput = createSaga(GET_TEMP_BLOODPRESSURE_DIRECTINPUT, healthAPI.get_Temp_BloodPressureDirectInput);
const Saga_BloodPressure_Input = createSaga(GET_BLOODPRESSURE_INPUT, healthAPI.get_BloodPressure_Input);
const Saga_BloodSugar_Input = createSaga(GET_BLOODSUGAR_INPUT, healthAPI.get_BloodSugar_Input);
const Saga_Stress = createSaga(GET_STRESS, healthAPI.getStress);
const Saga_Stress_Band = createSaga(GET_STRESS_BAND, healthAPI.getStressBand);
const Saga_Steps = createSaga(GET_STEPS, healthAPI.getSteps);
const Saga_Temperature = createSaga(GET_TEMPERATURE, healthAPI.getTemperature);
const Saga_Band_Status = createSaga(GET_BAND_STATUS, healthAPI.getBandStatus);

export function* userSaga() {
  yield takeLatest(GET_BODYCOMP, Saga_BodyComp);
  yield takeLatest(GET_USER_INFO, Saga_GetUserInfo);
  yield takeLatest(GET_GOAL_ACHIEVE, Saga_GoalAchieve);
  yield takeLatest(GET_MISSION_RATE, Saga_MissionRate);
  yield takeLatest(USER_EDIT_INFORMATION, Saga_EditInfo);
  yield takeLatest(USER_EDIT_PASSWORD, Saga_EditPassword);
  yield takeLatest(GET_BPM, Saga_Bpm);
  yield takeLatest(GET_BPM_BAND, Saga_Bpm_Band);
  yield takeLatest(GET_RUN, Saga_Run);
  yield takeLatest(GET_SLEEP, Saga_Sleep);
  yield takeLatest(GET_SLEEP_PERIOD, Saga_Sleep_Period);
  yield takeLatest(GET_BLOODPRESSURE, Saga_BloodPressure);
  yield takeLatest(GET_TEMP_BLOODPRESSURE_DIRECTINPUT, Saga_Temp_BloodPressure_DirectInput);
  yield takeLatest(GET_BLOODPRESSURE_INPUT, Saga_BloodPressure_Input);
  yield takeLatest(GET_BLOODSUGAR_INPUT, Saga_BloodSugar_Input);
  yield takeLatest(GET_STRESS, Saga_Stress);
  yield takeLatest(GET_STRESS_BAND, Saga_Stress_Band);
  yield takeLatest(GET_STEPS, Saga_Steps);
  yield takeLatest(GET_TEMPERATURE, Saga_Temperature);
  yield takeLatest(GET_BAND_STATUS, Saga_Band_Status);
}

// BodyComp Data 저장 액션함수
export const getUserInfo = createAction(GET_USER_INFO, value => value);
export const getBodyComp = createAction(GET_BODYCOMP,({period}) => ({period}));
export const getGoalAchieve = createAction(GET_GOAL_ACHIEVE,({period}) => ({period}));
export const getMissionRate = createAction(GET_MISSION_RATE);
export const resetForm = createAction(RESET_FORM,form => form);
export const getBpm = createAction(GET_BPM, data => data);
export const getBpmBand = createAction(GET_BPM_BAND, page => page);
export const getRun = createAction(GET_RUN, data => data);
export const getSleep = createAction(GET_SLEEP, page => page);
export const getSleepPeriod = createAction(GET_SLEEP_PERIOD, data => data);
export const getBloodPressure = createAction(GET_BLOODPRESSURE, page => page);
export const getTemp_BloodPressureDirectInput = createAction(GET_TEMP_BLOODPRESSURE_DIRECTINPUT, memberSeq => memberSeq);
export const getBloodPressureInput = createAction(GET_BLOODPRESSURE_INPUT, data => data);
export const getBloodSugarInput = createAction(GET_BLOODSUGAR_INPUT, data => data);

export const getStress = createAction(GET_STRESS, data => data);
export const getStressBand = createAction(GET_STRESS_BAND, page => page);
export const getSteps = createAction(GET_STEPS, data => data);
export const getTemperature = createAction(GET_TEMPERATURE, data => data);
export const getBandStatus = createAction(GET_BAND_STATUS);
export const changeUserField = createAction(
  USER_CHANGE_INFORMATION,
  ({ form, key, value }) => ({
    form, key, value,
  }),
);
export const editInfo = createAction(
  USER_EDIT_INFORMATION,
  ({UserEmail, UserName, UserSeq, UserPhone}) => ({
    UserEmail,UserName, UserSeq, UserPhone
  })
)

// 초기상태 정의
const initialState = {
  userEdit: {
    UserId: '',
    UserName: '',
    UserEmail: '',
    UserSeq: '',
    UserPhone: '',
    UserPassword: '',
    UserRePassword: '',
    UserUrl: null,
  },
  bodyComp: {},
  goalAchieve: {},
  missionRate: {},
  profileImg: '',
  userInfo: {},
  //health indicators
  bpm: {},
  bpmBand: {},
  run: {},
  sleep: {},
  sleepPeriod: {},
  bloodPressure: {},
  temp_bloodPressureDirectInput: {},
  bloodPressureInput: {},
  bloodSugarInput: {},
  bpmDate: dateModule,
  stepsDate: dateModule,
  stressDate: dateModule,
  tempDate: dateModule,
  runDate: dateModule,
  sleepDate: dateModule,
  bloodPressureDate: dateModule,
  bloodSugarDate: dateModule,
  stress: {},
  stressBand: [],
  steps: {},
  temperature: {},
  editSuccess: false,
  bandStatus: {
    heartrate: {},
    bp: {},
    sleep: {},
    stress: {},
    run: {}
  },
};

//적용한 액션 리듀서 적용 (Switch 대신 handleActions 사용)
export default handleActions({
  [RESET_FORM]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [USER_CHANGE_INFORMATION]: (state, { payload: { form, key, value } }) =>
    produce(state, draft => {
      draft[form][key] = value;
    }),
  [GET_USER_INFO_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    userInfo: data,
    userEdit: {
      UserId: data.id,
      UserName: data.name,
      UserEmail: data.email,
      UserSeq: data.memberSeq,
      UserUrl: data.urlProfile,
      UserPhone: data.phone,
    },
  }),
  [GET_BODYCOMP_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bodyComp: data,
  }),
  [GET_GOAL_ACHIEVE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    goalAchieve: data,
  }),
  [GET_MISSION_RATE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    missionRate: data,
  }),
  [USER_EDIT_INFORMATION_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    editSuccess: data === 200,
  }),
  [GET_BPM_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bpm: data,
  }),
  [GET_BPM_BAND_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bpmBand: data,
  }),
  [GET_RUN_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    run: data.list,
  }),
  [GET_SLEEP_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    sleep: data,
  }),
  [GET_SLEEP_PERIOD_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    sleepPeriod: data,
  }),
  [GET_BLOODPRESSURE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bloodPressure: data,
  }),
  [GET_TEMP_BLOODPRESSURE_DIRECTINPUT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    temp_bloodPressureDirectInput: data.map(e => ({...e, insDte : e.insDte - 9 * 60 * 60 * 1000}))
  }),
  [GET_BLOODPRESSURE_INPUT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bloodPressureInput: data.list
  }),
  [GET_BLOODSUGAR_INPUT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bloodSugarInput: data.list
  }),
  [GET_STRESS_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    stress: data,
  }),
  [GET_STRESS_BAND_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    stressBand: data,
  }),
  [GET_STEPS_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    steps: data,
  }),
  [GET_TEMPERATURE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    temperature: data,
  }),
  [GET_BAND_STATUS_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    bandStatus: data,
  }),
}, initialState);

