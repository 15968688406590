import React from 'react';
import styled from 'styled-components';

const ChartBodyForm = styled.div`
    height: ${props => props.height}px;
    width: ${props => props.width}%;
  `;

const ChartBody = props => {
  return (
    <ChartBodyForm height={props.height} width={props.width}>
      {props.children}
    </ChartBodyForm>
  )
};

export default ChartBody;