import React, { useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import {
  ChartLegendBodyComp,
  ChartLegendGoal,
  ChartLegendScatter,
} from '../components/ChartLegend';
import { MngChartView } from '../chart/MngChartView';
import Card from '../components/Card';
import $ from 'jquery';
import { ProgressBar } from 'react-bootstrap';
import { MathRound } from '../../utils/UseFunc/UseFunction';
import { HiUsers, RiMore2Line } from 'react-icons/all';
import ChartBody from '../components/ChartBody';
import { AdminColor, FemaleColor, MaleColor } from '../../lib/styles/palette';
import { GET_UNIT_PERCENTAGE } from '../../utils/UseFunc/UseStorage';
import ModalDashDetail from '../components/modal/ModalDashDetail';
import { Link } from 'react-router-dom';
import { StyleButton } from '../components/Button';

const DashBoardBody = styled.div`
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    
    li {
      font-size: 1rem;
      width: 25%;
    }
  }
`;

const DashBoardCardSec = styled.div`
  display: flex;
  border: none!important;
  box-shadow: none!important;
`;

const MdGender = styled.div`
  padding: .5rem;

  h1 {
    margin-bottom: 1rem;
  }
  
  svg {
    float: right;
    height: 25px;
    width: 25px;
  }

  .progress {
    height: 5px;
    border-radius: .5rem;
    background-color: #e2e1ea;
  }
  
  .mdGender-male .progress-bar {
    background-color: ${MaleColor};
  }
  .mdGender-female .progress-bar {
    background-color: ${FemaleColor};
  }
  
  .mbGender-label {
    display: flex;
    margin-top: 3px;
    font-size: 14px;
    color: #92969a;
    
    div {
      width: 50%;
      
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
`;

const MdDetail = styled.div`
  svg {
    float: right;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`;

const AdminCompanyDetail = props => {
  if(props.tabletCheck) {
    $('.mainHeader').addClass('Hide');
  }

  const gender = props.ttlUserAndGenderData;
  const malePct = MathRound(gender.maleCnt / gender.totalCnt * 100, 10) || 0;
  const femalePct = MathRound(gender.femaleCnt / gender.totalCnt * 100, 10) || 0;
  const [modal, setModal] = useState({
    visible: false,
    name: '',
    onChange: null
  });

  const modalOpen = name => {
    setModal({
      visible: true,
      name,
      onChange: props.onChange
    });
  }

  const modalClose = () => {
    setModal({ visible: false});
  }

  return (
    <>
      <DashBoardBody>
        {modal.visible &&
          <ModalDashDetail
            index={props.index}
            visible={modal.visible}
            onClose={modalClose}
            name={modal.name}
            onChange={modal.onChange}
          />
        }
        
        <div className="col-2 ml-auto mb-3">
          <Link to="/admin/list">
            <StyleButton
              text="돌아가기"
              background={AdminColor}
            />
          </Link>
        </div>

        <DashBoardCardSec className="no-padding">
          <div className="col-4">
            <Card
              height="auto"
            >
              <MdGender>
                <div className="mb-2">
                  <span>{i18next.t('MngDashCardHeadText01')}</span>

                  <HiUsers color={MaleColor}/>
                </div>

                <h1>{gender.maleCnt || 0}</h1>

                <div>
                  <ProgressBar className="mdGender-male" now={malePct}/>
                </div>

                <div className="mbGender-label">
                  <div>
                    {i18next.t('GenderM')}
                  </div>

                  <div>
                    {malePct}%
                  </div>
                </div>
              </MdGender>
            </Card>

            <Card
              height="auto"
            >
              <MdGender>
                <div className="mb-2">
                  <span>{i18next.t('MngDashCardHeadText01')}</span>

                  <HiUsers color={FemaleColor}/>
                </div>

                <h1>{gender.femaleCnt || 0} </h1>

                <div>
                  <ProgressBar className="mdGender-female" now={femalePct}/>
                </div>

                <div className="mbGender-label">
                  <div>
                    {i18next.t('GenderF')}
                  </div>

                  <div>
                    {femalePct}%
                  </div>
                </div>
              </MdGender>
            </Card>
          </div>

          <div className="col-8">
            <Card
              height="auto"
            >
              <div className="p-2">
                <div className="mb-4">
                  {i18next.t('MngDashCardHeadText03')}
                </div>

                <ChartBody height={272}>
                  <MngChartView
                    ChartData={props.wtData}
                    ChartType="Weight"
                  />
                </ChartBody>
              </div>
            </Card>
          </div>
        </DashBoardCardSec>

        <DashBoardCardSec className="no-padding">
          <div className="col-7">
            <Card
              height="auto"
            >
              <div className="p-2">
                <MdDetail >
                  {i18next.t('MngDashCardHeadText04')}

                  <RiMore2Line
                    onClick={() => modalOpen('MngScatterPlot')}
                  />
                </MdDetail>

                <ChartLegendScatter/>

                <ChartBody height={400}>
                  <MngChartView
                    ChartData={props.sctPlotData}
                    ChartType="ScatterPlot"
                  />
                </ChartBody>
              </div>
            </Card>
          </div>

          <div className="col-5">
            <Card
              height="auto"
            >
              <div className="p-2">
                <div className="mb-4">
                  {i18next.t('MngDashCardHeadText02')}
                </div>

                <ChartBody height={250}>
                  <MngChartView
                    ChartData={props.ttlAgeData}
                    ChartType="TotalAge"
                  />
                </ChartBody>
              </div>
            </Card>

            <Card
              height="auto"
            >
              <div className="p-2">
                <div className="d-flex align-items-center">
                  <div className="col-8 p-0">
                    <div className="mb-4">
                      {i18next.t('MngDashCardHeadText06')}
                    </div>

                    <h1 className="m-0">
                      {props.missionData ? props.missionData.avgAchieveRate : 0}
                      <span style={{fontSize: '1.5rem'}}>{GET_UNIT_PERCENTAGE}</span>
                    </h1>
                  </div>

                  <div className="col-4">
                    <ChartBody height={100}>
                      <MngChartView
                        ChartData={props.missionData}
                        ChartType="Mission"
                      />
                    </ChartBody>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </DashBoardCardSec>

        <DashBoardCardSec className="no-padding">

          <div className="col-12">
            <Card
              height="auto"
            >
              <div className="p-2">
                <MdDetail >
                  {i18next.t('MngDashCardHeadText05')}

                  <RiMore2Line
                    onClick={() => modalOpen('MngBodyComp')}
                  />
                </MdDetail>

                <ChartLegendBodyComp legendLength={3}/>

                <ChartBody height={300}>
                  <MngChartView
                    ChartData={props.bodyCompData}
                    ChartType="BodyComp"
                  />
                </ChartBody>
              </div>
            </Card>
          </div>
        </DashBoardCardSec>

        <DashBoardCardSec className="no-padding">
          <div className="col-12">
            <Card
              height="auto"
            >
              <div className="p-2">
                <MdDetail >
                  {i18next.t('MngDashCardHeadText07')}

                  <RiMore2Line
                    onClick={() => modalOpen('MngGoalAchieve')}
                  />
                </MdDetail>

                <ChartLegendGoal/>

                <ChartBody height={300}>
                  <MngChartView
                    ChartData={props.goalAchieveData}
                    ChartType="GoalAchieve"
                  />
                </ChartBody>
              </div>
            </Card>
          </div>
        </DashBoardCardSec>
      </DashBoardBody>
    </>
  );
};

export default AdminCompanyDetail;