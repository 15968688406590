import React, { useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import Button, { MngButtonPopup } from './Button';
import palette, { DftColor } from '../../lib/styles/palette';
import Popup from 'reactjs-popup';
import ModalUsrInfoCrud from './modal/ModalUsrInfoCrud';
import { AiOutlineArrowRight } from 'react-icons/all';
import downArrow from '../../lib/styles/img/icon/iconArrowDown.png';

const CardHeaderBody = styled.div`
  padding-bottom: 10px;
  margin: 0;
  border-bottom: 1px solid #cec9c9;
  font-size: 0.875rem;
  font-weight: 600;
  
`;

const CardSelect = styled.select`
  float: right;
  font-size: 0.875rem;
  border: none;
  width: 150px;
  border-radius: 5px;  
  background-color: #dedede;
  color: #000;
  position: relative;
  bottom: 7px;
  height: 30px;
  padding: 0 1rem;
  background-image: url(${downArrow}); 
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 97%;
  outline: none;
  cursor: pointer;
  
  // 기존 Select 화살표 삭제
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
`;

const CardButton = styled(MngButtonPopup)`
  float: right;
  position: relative;
  bottom: 8px;
`;

const DetailButton = styled.button`
  border: none;
  float: right;
  position: relative;
  bottom: 5px;
  background-color: ${DftColor};
  color: #FFF;
  min-width: 100px!important;
  height: 30px!important;
  font-size: 0.8rem;
  border-radius: 3px!important;
  
  svg {
    margin-left: 5px;
  }
`;

const CardHeader = props => {
  const [period, setPeriod] = useState();
  const PeriodFilter = ({name, type}) => {
    const onChange = e => {
      setPeriod(e.target.value);

      switch (name) {
        case 'BodyComp' :
          props.onChange(e);
          break;
        case 'GoalAchieve' :
          props.onChange(e);
          break;
        case 'mngWeight' :
          props.onChange(e);
          break;
        case 'mngScatterPlot' :
          props.onChange(e);
          break;
        case 'mngBodyComp' :
          props.onChange(e);
          break;
        case 'mngGoalAchieve' :
          props.onChange(e);
          break;
        case 'AvgRank' :
          props.onChange(e);
          break;
        default:
          break;
      }
    };

    return (
      <>
        {type === 'Body' ?
          <CardSelect name={name} onChange={onChange} value={period}>
            <option id="A">{i18next.t('OtherCtg07')}</option>
            <option id="B">{i18next.t('AnalysisHeader10')}</option>
            <option id="S">{i18next.t('AnalysisHeader11')}</option>
          </CardSelect>
          :
          <>
            <DetailButton onClick={props.onOpen}>
              {i18next.t('ButtonDetail')}
              <AiOutlineArrowRight />
            </DetailButton>
          </>
        }
      </>
    );
  };

  return (
    <CardHeaderBody>
      {props.header}

      {props.filter && <PeriodFilter name={props.filterName} type={props.filterType} />}
      {props.filterName === 'Button' &&
      <Popup modal trigger={<CardButton background={DftColor}>{i18next.t('ButtonEdit')}</CardButton>} closeOnDocumentClick={false}>
        {close => (
          <ModalUsrInfoCrud
            HeaderText={i18next.t('MyInfoCrudHeader')}
            CloseModal={close}
            userInfoData={props.userInfoData}
          />
        )}
      </Popup>
      }
    </CardHeaderBody>
  );
};

export default CardHeader;