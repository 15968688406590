import React, { useEffect } from 'react';
import { Login } from '../../utils/UseFunc/UseFunction';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { finishLoading, startLoading } from '../../utils/Request/RequestLoading';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { SET_ALERT } from '../../modules/common/AlertModule';

/*
  apiToken[1] = code
  apiToken[2] = id_token
*/

const AuthAppleSnsLogin = props => {
  const apiToken = window.location.href.split('=');
  const dispatch = useDispatch();
  const decoded = jwtDecode(apiToken[2]);

  useEffect(() => {
    dispatch(startLoading());

    appleLogin().then(state => {
      state.type === 'U' ?
        props.history.push('/usr/info')
        : state.type === 'A' ?
        props.history.push('/company/create')
        : (state.type === 'M' || state.type === 'S') &&
        props.history.push('/mng/dashboard');

      if(state.error !== '') {
        dispatch(
          SET_ALERT({
            type: 'Warning',
            text: i18next.t('ErrorNonReg'),
            submit: {
              act: props.history.push('/'),
            },
          })
        );
      }

      dispatch(finishLoading());
    });
  }, [dispatch]);
  
  const appleLogin = async () => {
    return await Login(decoded.sub, null, 'A');
  }

  return '';
};

export default withRouter(AuthAppleSnsLogin);