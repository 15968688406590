import React from 'react';
import styled from 'styled-components';

const Box = styled.span`
  input[type=checkbox] + label:before {
    border-color: ${props => props.color};
    cursor: pointer;
    top: -15px
  }
  input[type=checkbox]:checked + label:before {
    top: -20px;
    border-color: transparent ${props => props.color} ${props => props.color} transparent;
  }
  
  input[type=checkbox] {
    display: none!important;
  }
  
  label {
    margin: 0;
  }
`;

const CheckBox = props => {
    return (
      <Box color={props.color}>
        <input type="checkbox" id={'chk' + props.uKey} onClick={props.onChange} checked={props.checked}/>
        <label htmlFor={'chk' + props.uKey} />
      </Box>
    );
};

export default CheckBox;