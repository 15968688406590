import React, { useCallback, useEffect, useState } from 'react';
import AdminTemplate from '../../view/admin/layout/AdminTemplate';
import AdminSetting from '../../view/admin/AdminSetting';
import { changeField, initializeForm, SET_CHANGE_PASSWORD } from '../../modules/auth/AuthModules';
import i18next from 'i18next';
import { CheckInfoRule } from '../../utils/UseFunc/UseFunction';
import { CHECK_PASSWORD, RESET_FORM } from '../../modules/manager/MemberModules';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ALERT } from '../../modules/common/AlertModule';
import { GET_MEMBERSEQ } from '../../utils/UseFunc/UseStorage';

const AdminSettingController = props => {
  const dispatch = useDispatch();
  const [pwError, setPwError] = useState({
    cPwError: '',
    nPwError: '',
  });
  const {
    pwInfo,
    pwChangeSuccess,
    checkPasswordSuccess,
    changePasswordError,
  } = useSelector(({ auth, member }) => ({
    pwInfo: auth.changePw,
    pwChangeSuccess: auth.changeAuth,
    checkPasswordSuccess: member.checkPasswordSuccess,
    changePasswordError: auth.changePasswordError,
  }));

  useEffect(() => {
    dispatch(initializeForm('changePw'));
  }, [dispatch]);

  useEffect(() => {
    if (checkPasswordSuccess) {
      if (checkPasswordSuccess === 200) {
        setPwError(prev => ({ ...prev, cPwError: '현재 비밀번호와 일치합니다.' }));
      }

      if (checkPasswordSuccess === 409) {
        setPwError(prev => ({ ...prev, cPwError: i18next.t('ErrorCurrnetPwNotMath') }));
      }
    }

    if (changePasswordError) {
      if (changePasswordError === 406) {
        setPwError(prev => ({ ...prev, nPwError: i18next.t('ErrorCurrnetPw') }));
      }
    }

    if (pwChangeSuccess) {
      setPwError({ cPwError: '', nPwError: '' });
      dispatch(SET_ALERT({ type: 'Success', text: i18next.t('SuccessEdit') }));
      dispatch(initializeForm('changeAuth'));
      dispatch(RESET_FORM('checkPasswordSuccess'));
      dispatch(initializeForm('changePw'));
    }
  }, [changePasswordError, checkPasswordSuccess, dispatch, pwChangeSuccess]);
  
  const onPwChange = useCallback(e => {
    const { name, value } = e.target;

    dispatch(
      changeField({
        form: 'changePw',
        name,
        value,
      }),
    );
  }, [dispatch]);

  const onPwSubmit = useCallback(() => {
    const { oldPw, newPw, newPwRe } = pwInfo;

    if([oldPw].includes('')) {
      setPwError(prev => ({ ...prev, cPwError: i18next.t('ErrorBlank') }));
      return;
    }

    if (!CheckInfoRule('pw', newPw)) {
      setPwError(prev => ({ ...prev, nPwError: i18next.t('ErrorIdType') }));
      return;
    }

    if(newPw !== newPwRe) {
      setPwError(prev => ({ ...prev, nPwError: i18next.t('ErrorPwNotMatch') }));
      return;
    }

    dispatch(
      SET_CHANGE_PASSWORD({
        mSeq: GET_MEMBERSEQ(),
        newPw,
        oldPw
      }),
    );
  }, [dispatch, pwInfo]);

  const onPwCheck = useCallback(e => {
    dispatch(RESET_FORM('checkPasswordSuccess'));

    if(e.target.value.length > 0) {
      dispatch(CHECK_PASSWORD({ mSeq: GET_MEMBERSEQ(), pw: e.target.value }));
    }
  }, [dispatch]);
  
  return (
    <AdminTemplate>
      <AdminSetting
        pwInfo={pwInfo}
        onPwError={pwError}
        onPwCheck={onPwCheck}
        onPwCheckStatus={checkPasswordSuccess}
        onPwChange={onPwChange}
        onPwSubmit={onPwSubmit}
      />
    </AdminTemplate>
  )
};

export default AdminSettingController;