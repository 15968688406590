import React from 'react';
import styled from 'styled-components';
import AdminChangePw from './setting/AdminChangePw';

const SettingBox = styled.div`
  background-color: #FFF;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
`;

const AdminSetting = props => {
  return (
    <div className="d-flex">
      <div className="col-4">
        <SettingBox>
          <AdminChangePw
            pwInfo={props.pwInfo}
            error={props.onPwError}
            onCheck={props.onPwCheck}
            onCheckStatus={props.onPwCheckStatus}
            onClose={props.onPwModalClose}
            onChange={props.onPwChange}
            onSubmit={props.onPwSubmit}
          />
        </SettingBox>
      </div>
    </div>
  )
};

export default AdminSetting;