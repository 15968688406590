import React from 'react';
import styled from 'styled-components';
import { DftColor } from '../../../lib/styles/palette';

const ModalTitle = styled.div`
  text-align: center;
  padding: 15px;
  border-radius: ${props => props.border || '25px 25px 0 0'};
  background-color: ${DftColor};
  color: white;
  font-size: 1.3rem;
  
  &.Danger {
    background-color: #ef4a4a!important;
  }
  
  &.Success {
    background-color: #61ce61;
  }
`;

const ModalBody = styled.div`

`;

const ModalFrame = props => {
  return (
    <div style={{height : props.height}}>
      <ModalTitle className={props.AlertStr} border={props.border}>
        {props.HeaderText}
      </ModalTitle>

      <ModalBody>
        {props.children}
      </ModalBody>
    </div>
  );
};

export default ModalFrame;