import { callApi } from './index';
import { CheckTypeForManager, MathRound } from '../utils/UseFunc/UseFunction';
import { GET_MEMBER_TYPE, SET_UNIT_WEIGHT } from '../utils/UseFunc/UseStorage';

const Path = '/manager';

export const getTotalUserGender = index => {
  return callApi().get( `${Path}/getMembersGenderCnt${index ? '?companySeq=' + index : ''}`);
}

export const getTotalAge = index => {
  return callApi().get(`${Path}/getMembersAgeUnit${index ? '?companySeq=' + index : ''}`)
    .then(res => {
      const data = {
        female : [],
        male : []
      }

      res.data.list.forEach((value, index) => {
        data.female.push(value.femaleCount);
        data.male.push(value.maleCount);
      })

      res.data = data;

      return res;
    })
}

export const getWeight = data => {
  return callApi().get(`${Path}/getMembersWeightUnit?period=${data.period}${data.index ? '&companySeq=' + data.index : ''}`)
    .then(res => {
      const data = {
        female : [],
        male : []
      }

      res.data.list.forEach((value, index) => {
        data.female.push(value.femaleCount);
        data.male.push(value.maleCount);
      })

      res.data = data;

      return res;
    })
}

export const getScatterPlot = data => {
  const type = data.type ? data.type : 'Dashboard';
  const date = data.type ? '&sDate=' + data.sDate + '&eDate=' + data.eDate : '';
  const index = data.index ? `&companySeq=${data.index}` : '';
  return callApi().get(Path + '/scatterPlot?pageType=' + type + date + index)
    .then(res => {
      const sctMAX = SET_UNIT_WEIGHT(70);
      const dataF = [];
      const dataM = [];
      const infoF = [];
      const infoM = [];
      const avgBfm = [{x: SET_UNIT_WEIGHT(res.data.avgBfm), y: 0}, {x: SET_UNIT_WEIGHT(res.data.avgBfm), y: sctMAX}];
      const avgSmm = [{x: 0, y: SET_UNIT_WEIGHT(res.data.avgSmm)}, {x: sctMAX, y: SET_UNIT_WEIGHT(res.data.avgSmm)}];

      res.data.list.forEach(value => {
        if(value.gender === 'F') {
          dataF.push({
            x: SET_UNIT_WEIGHT(value.bfm),
            y: SET_UNIT_WEIGHT(value.smm),
          });

          infoF.push({
            seq: value.memberSeq,
            name: value.memberName,
          });
        } else {
          dataM.push({
            x: SET_UNIT_WEIGHT(value.bfm),
            y: SET_UNIT_WEIGHT(value.smm),
          });

          infoM.push({
            seq: value.memberSeq,
            name: value.memberName,
          });
        }
      })

      res.data = { dataF, dataM, avgBfm, avgSmm, infoF, infoM };

      return res;
    })
}

export const getBodyComp = data => {
  const type = data.type ? data.type : 'Dashboard';
  const date = data.type ? '&sDate=' + data.sDate + '&eDate=' + data.eDate : '';
  const index = data.index ? `&companySeq=${data.index}` : '';
  const reUrl = window.location.pathname.split('/');
  let url;
  if(GET_MEMBER_TYPE() === 'U') {
    url = CheckTypeForManager('/user/getBodyComp?pageType=' + type + date + index, 2)
  } else {
    if(reUrl[1] === 'mng' || reUrl[1] === 'admin')
      url = '/manager/getBodyCompForManager?pageType=' + type + date + index;
    else
      url = CheckTypeForManager('/user/getBodyComp?pageType=' + type + date + index, 2)
  }
  return callApi().get(url)
    .then(res => {
      const data = {
        wt : [],
        bfm : [],
        smm : [],
        date : []
      }

      res.data.list.forEach((value, index) => {
        data.wt.push(value.weight > 0 ? SET_UNIT_WEIGHT(value.weight) : data.wt[index - 1] || 0);
        data.bfm.push(value.weight > 0 ? SET_UNIT_WEIGHT(value.weight - value.bfm) : data.bfm[index - 1] || 0);
        data.smm.push(value.weight > 0 ? SET_UNIT_WEIGHT(value.weight - value.bfm - value.smm) : data.smm[index - 1] || 0);
        data.date.push(new Date(value.mdte).format('MM-dd'));
      });

      res.data = data;

      return res;
    })
}

export const getGoalAchieve = data => {
  const type = data.type ? data.type : 'Dashboard';
  const date = data.type ? '&sDate=' + data.sDate + '&eDate=' + data.eDate : '';
  const index = data.index ? `&companySeq=${data.index}` : '';
  const reUrl = window.location.pathname.split('/');
  let url;

  if(GET_MEMBER_TYPE() === 'U') {
    url = CheckTypeForManager('/user/getGoalAchieve?pageType=' + type + date + index, 2)
  } else {
    if(reUrl[1] === 'mng' || reUrl[1] === 'admin')
      url = '/manager/getGoalAchieveForManager?pageType=' + type + date + index;
    else
      url = CheckTypeForManager('/user/getGoalAchieve?pageType=' + type + date + index, 2)

  }
  return callApi().get(url)
    .then(res => {
      const data = {
        date: [],
        smm: [],
        bfm: [],
      };

      res.data.list.forEach((value, index) => {
        data.smm.push(value.smmGoalRate ? value.smmGoalRate : data.smm[index - 1] || 0);
        data.bfm.push(value.bfmGoalRate ? value.bfmGoalRate : data.bfm[index - 1] || 0);
        data.date.push(new Date(value.date).format('MM-dd'));
      });

      res.data = data;

      return res;
    })
}

export const getMissionRate = index => {
  return callApi().get(`/mission/statics${index ? '?companySeq=' + index : ''}`);
}

export const getMissionMemList = async data => {
  return await callApi().get('/manager/missionClearMemberList?startDate=' + data.sDate + '&endDate=' + data.eDate)
    .then(res => {
      const list = [];

      res.data.list.forEach(async value => {
        list.push({
          id : value.id,
          name : value.name,
          mPer : MathRound(value.percentage, 10),
          uIndex : value.uindex
        });
      })

      res.data = list;

      return res;
    });
}

export const getMissionPer = data => {
  return callApi().get('/manager/missionClearPercentage?startDate=' + data.sDate + '&endDate=' + data.eDate)
    .then(res => {
      const data = {
        date: [],
        per: [],
      };

      res.data.list.forEach(value => {
        data.date.push(new Date(value.date).format('yyyy-MM-dd'));
        data.per.push(MathRound(value.percentage, 10));
      })

      res.data = data

      return res;
    });
}
