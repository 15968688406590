import { callApi } from './index';
import { CheckTypeForManager, GenToPercentage } from '../utils/UseFunc/UseFunction';
import {
  GET_DEVICE_NAME, GET_MEMBER_TYPE, GET_MEMBERSEQ, GET_MEMBERSEQ_FOR_MNG,
  GET_UNIT_CALORIE,
  GET_UNIT_PERCENTAGE,
  GET_UNIT_WEIGHT,
  SET_UNIT_CALORIE,
  SET_UNIT_WEIGHT,
} from '../utils/UseFunc/UseStorage';

export const getBodyPercentage = ({ bodyPage, device }) => {
  let deviceString = ''
  if ( device === 'FN' || device === 'FT' ) { 
    deviceString = '&devCode=FN&devCode=FT'
  } else if ( device !== undefined ) {
    deviceString = '&devCode=' + device
  } 

  const url = '/body/detail?page=' + bodyPage + deviceString;
  return callApi().get(CheckTypeForManager(url, 1))
    .then(res => {
      res.data = {
        genBfm: GenToPercentage(res.data.genBfm),
        genBfp: GenToPercentage(res.data.genBfp),
        genBmi: GenToPercentage(res.data.genBmi),
        genBmr: GenToPercentage(res.data.genBmr),
        genBwp: GenToPercentage(res.data.genBwp),
        genSmm: GenToPercentage(res.data.genSmm),
        genWeight: GenToPercentage(res.data.genWeight),
        genEcf: GenToPercentage(res.data.genEcf),
        genProtein: GenToPercentage(res.data.genProtein),
        genMinerals: GenToPercentage(res.data.genMinerals),
        detail: {
          bfp: res.data.bfp + GET_UNIT_PERCENTAGE,
          bfm: SET_UNIT_WEIGHT(res.data.bfm) + GET_UNIT_WEIGHT(),
          smm: SET_UNIT_WEIGHT(res.data.smm) + GET_UNIT_WEIGHT(),
          bmr: SET_UNIT_CALORIE(res.data.bmr) + GET_UNIT_CALORIE(),
          bmi: res.data.bmi,
          wt: SET_UNIT_WEIGHT(res.data.weight) + GET_UNIT_WEIGHT(),
          bwp: res.data.bwp + GET_UNIT_PERCENTAGE,
          ecf: res.data.ecf * 100 + GET_UNIT_PERCENTAGE,
          protein: res.data.protein + GET_UNIT_WEIGHT(),
          minerals: res.data.minerals + GET_UNIT_WEIGHT()
        },
      };

      return res;
    });
};

export const getSomatoType = ({ bodySeq }) => {
  // return callApi().get('/body/analysis?seq=' + bodySeq)
  return callApi().get('/body/newAnalysis?seq=' + bodySeq)
    .then(res => {
      sessionStorage.setItem('device', res.data.device);

      return res;
    });
};

export const getBodyGuide = ({ bodySeq }) => {
  return callApi().get('/body/guide?seq=' + bodySeq);
};

export const getBodyGoal = type => {
  return callApi().post(`/body/somatoGoal?memberSeq=${GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()}&goalGroup=${type}`, {
    UIndex: GET_MEMBERSEQ(),
    goalGroup: type,
  });
};

export const getGoal = type => {
  return callApi().get(`/body/somatoGoal?memberSeq=${GET_MEMBER_TYPE() === 'U' ? GET_MEMBERSEQ() : GET_MEMBERSEQ_FOR_MNG()}${type ? '&goal=' + type : ''}`);
};

export const setBodyGoal = ({ bodySeq, wgt, bfp }) => {
  const url = '/body/recommend?';
  const goalBfp = bfp > 0 ? 'goalBfp=' + bfp + '&' : '';
  const goalWgt = wgt > 0 ? 'goalWeight=' + wgt + '&' : '';
  const goalSeq = 'seq=' + bodySeq;

  return callApi().get(url + goalBfp + goalWgt + goalSeq);
};

export const getBodyTrend = ({ data, type }) => {
  const url = '/user/getMyTrend?startDate=' + data.sDate + '&endDate=' + data.eDate + (type === '' ? '' : '&devcode=' + type);
  return callApi().get(CheckTypeForManager(url, 1))
    .then(res => {
      const data = {
        bfp: [],
        bfm: [],
        bmi: [],
        bmr: [],
        score: [],
        smm: [],
        weight: [],
        ecf: [],
        protein: [],
        minerals: [],
        date: [],
      };

      //list => 배열
      res.data.list.forEach((value, index) => {
        data.bfp.push(value.bfp);
        data.bfm.push(value.bfm);
        data.bmi.push(value.bmi);
        data.bmr.push(value.bmr / 30);
        data.score.push(value.bodyScore);
        data.smm.push(value.smm);
        data.weight.push(value.weight);
        data.ecf.push(value.ecf);
        data.protein.push(value.protein);
        data.minerals.push(value.minerals);
        data.date.push(new Date(value.date).format('MM-dd'));
      });

      if (data.date.length < 10) {
        const arrayDate = new Array(10 - data.date.length).fill('');
        const arrayValue = new Array(10 - data.date.length).fill(null);

        data.date = arrayDate.concat(data.date);
        data.bfm = arrayValue.concat(data.bfm);
        data.smm = arrayValue.concat(data.smm);
        data.bfp = arrayValue.concat(data.bfp);
        data.bmi = arrayValue.concat(data.bmi);
        data.bmr = arrayValue.concat(data.bmr);
        data.score = arrayValue.concat(data.score);
        data.ecf = arrayValue.concat(data.ecf);
        data.protein = arrayValue.concat(data.protein);
        data.minerals = arrayValue.concat(data.minerals);
        data.weight = arrayValue.concat(data.weight);
      }

      res.data = data;

      return res;
    });
};

export const getBodyList = ({type = '', mSeq}) => {
  let deviceTypeString ='';
  if (type === '') {
    deviceTypeString = ''
  } else { 
    const typeArr = type.split(',')
    typeArr.forEach((t, idx) =>  {
      deviceTypeString += idx === 0 ? `?device=${t}`:`&device=${t}`
    })
  }
  
  const url = '/user/getBodyCompDetailList' + deviceTypeString + (mSeq ? '?memberSeq=' + mSeq : '');
  return callApi().get(mSeq ? url : CheckTypeForManager(url, type === '' ? 0 : 1))
    .then(res => {
      let date;
      let ft = 0, sm = 0, fl = 0, fa = 0;

      res.data.list.forEach((value, index) => {
        date = new Date(value.date).format('yyyy-MM-dd HH:mm');
        res.data.list[index].date = date;
        res.data.list[index].bodyScore = Math.floor(value.bodyScore);

        if (value.device === 'SM') {
          res.data.list[index].seq = sm + '/' + value.seq;
          sm++;
        } else if (value.device === 'FT' || value.device === 'FN') {
          res.data.list[index].seq = ft + '/' + value.seq;
          ft++;
        } else if (value.device === 'FL') {
          res.data.list[index].seq = fl + '/' + value.seq;
          fl++;
        } else if (value.device === 'FA') {
          res.data.list[index].seq = fa + '/' + value.seq;
          fa++;
        }

        res.data.list[index].weight = SET_UNIT_WEIGHT(value.weight) + GET_UNIT_WEIGHT();
        res.data.list[index].bfp = value.bfp + GET_UNIT_PERCENTAGE;
        res.data.list[index].smm = SET_UNIT_WEIGHT(value.smm) + GET_UNIT_WEIGHT();
        res.data.list[index].bfm = SET_UNIT_WEIGHT(value.bfm) + GET_UNIT_WEIGHT();
        res.data.list[index].bmr = SET_UNIT_CALORIE(value.bmr) + GET_UNIT_CALORIE();
        res.data.list[index].bwp = value.bwp + GET_UNIT_PERCENTAGE;
        if (value.device === 'FN') {
          res.data.list[index].device = GET_DEVICE_NAME('FT');
        } else {
          res.data.list[index].device = GET_DEVICE_NAME(value.device);
        }
        res.data.list[index].ecf = value.ecf * 100 + GET_UNIT_PERCENTAGE 
        res.data.list[index].protein = SET_UNIT_WEIGHT() + GET_UNIT_WEIGHT();
        res.data.list[index].minerals = SET_UNIT_WEIGHT() + GET_UNIT_WEIGHT();
      });

      return res;
    });
};

export const getAllData = () => {

}

/**
 * @typedef { Object } TeamDataItem
 * @property {string} account ID
 * @property {string} nickname 닉네임
 * @property {number} weight 체충
 * @property {`${number} %`} bfp 체지방률
 * @property {`${number} kg`} bfm 체지방량
 * @property {`${number} kg`} smm 골격근량
 * @property {`${number} KJ`} bmr 기초대사량
 * @property {`${number}`} bmi 체질량지수
 * @property {`${number} %`} bwp 체수분
 * @property {`${number}`} bodyScore 신체 점수
 * @property {string} date 측정 일시
 *  - ex) YYYY-MM-DD HH:mm
 */

/**
 * @typedef { Object } MemberDataResponse 
 * @property {number} seq 측정 데이터 PK
 * @property {number} uindex 사용자 PK
 * @property {string} id 아이디
 * @property {string} nickname 닉네임
 * @property {null} count ???
 * @property {number} date timestamp
 * @property {'FA'} device ???
 * @property {null} rawBfp ???
 * @property {number} bfp 체지방률
 * @property {number} bfm 체지방량
 * @property {number} smm 골격근량
 * @property {number} bmr 기초대사량
 * @property {number} bmi 체질량지수
 * @property {number} bwp 체수분
 * @property {number} weight 체중
 * @property {number} icw ???
 * @property {number} ecw ???
 * @property {number} protein ???
 * @property {number} minerals ???
 * @property {number} obesity ???
 * @property {number} abdomen ???
 * @property {number} calorie ???
 * @property {number} ecf ???
 * @property {number} bodyScore 신체 점수
 */

/**
 * @typedef {Object} MngInfoType
 * @property {null} gender
 * @property {null} height
 * @property {number} weight
 * @property {null} birth
 * @property {null} activity
 * @property {null} bodyType
 * @property {null} bodyStrengthType
 * @property {"ACT_0"} optActivity
 * @property {"MEAL_1"} optMeal
 * @property {null} painArea
 * @property {null} optNutrition
 * @property {null} correctType
 * @property {null} correctValue
 * @property {"dd@dd.cc"} email
 * @property {"manager1"} name
 * @property {null} countryCode
 * @property {"111"} phone
 * @property {null} devCode
 * @property {number} memberSeq
 * @property {"manager"} id
 * @property {"/member/profileImg/22?date=1671066179000"} urlProfile
 * @property {1593521027000} profileInsDate
 */

/**
 * @typedef {import('axios').AxiosResponse<{ list: MemberDataResponse[] }> } GetAllMemberDataResponse
 */

/**
 * @param {MngInfoType} [mngInfo]
 * @returns { Promise<TeamDataItem[] | Error> }
 * */
// eslint-disable-next-line arrow-parens
export const getTeamMeasurementList = async (mngInfo) => {
  try {
    /** @type { GetAllMemberDataResponse } */
    const res = await callApi()
      .get(
        '/manager/getMemberDataExcel',
        {
          params: {
            mngIndex: mngInfo?.memberSeq || null
          }
        }
      )

    /** @type { TeamDataItem[] } */
    const manufacture = res.data.list.map(
      // eslint-disable-next-line arrow-parens
      (cur) => ({
        account: cur.id,
        nickname: cur.nickname,
        weight: cur.weight + GET_UNIT_WEIGHT(),
        bfp: cur.bfp + GET_UNIT_PERCENTAGE,
        bfm: SET_UNIT_WEIGHT(cur.bfm) + GET_UNIT_WEIGHT(),
        smm: SET_UNIT_WEIGHT(cur.smm) + GET_UNIT_WEIGHT(),
        bmr: SET_UNIT_CALORIE(cur.bmr) + GET_UNIT_CALORIE(),
        bmi: cur.bmi,
        bwp: cur.bwp + GET_UNIT_PERCENTAGE,
        bodyScore: cur.bodyScore,
        date: new Date(cur.date).format('yyyy-MM-dd HH:mm'),
      })
    )

    return manufacture
  } catch (e) {
    console.error(e)
    throw e
  }
}
