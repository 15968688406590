import { createAction, handleActions } from 'redux-actions';
import createSaga, { createActionTypes } from '../../utils/Request/RequestCreateSaga';
import * as MESSAGE_API from '../../api/MessageApi';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import { initialState } from '../initialState';


const ACTION_CHANGE_NOTICE = 'message/ACTION_CHANGE_NOTICE';
const ACTION_RESET_NOTICE = 'message/ACTION_RESET_NOTICE';
const [ACTION_GET_NOTICE, ACTION_GET_NOTICE_SUCCESS] = createActionTypes('message/ACTION_GET_NOTICE');
const [ACTION_SET_NOTICE, ACTION_SET_NOTICE_SUCCESS] = createActionTypes('message/ACTION_SET_NOTICE');

const SAGA_GET_NOTICE = createSaga(ACTION_GET_NOTICE, MESSAGE_API.getNoticeList);
const SAGA_SET_NOTICE = createSaga(ACTION_SET_NOTICE, MESSAGE_API.setNoticeList);

export function* messageSaga() {
  yield takeLatest(ACTION_GET_NOTICE, SAGA_GET_NOTICE);
  yield takeLatest(ACTION_SET_NOTICE, SAGA_SET_NOTICE);
}
export const GET_NOTICE = createAction(ACTION_GET_NOTICE);
export const SET_NOTICE = createAction(ACTION_SET_NOTICE, form => form)
export const CHANGE_NOTICE = createAction(ACTION_CHANGE_NOTICE, ({form, name, value}) => ({form, name, value}));
export const RESET_NOTICE = createAction(ACTION_RESET_NOTICE, form => form)

export default handleActions({
  [ACTION_CHANGE_NOTICE]: (state, { payload: { form, name, value } }) =>
    produce(state, draft => {
      draft[form][name] = value;
    }),
  [ACTION_RESET_NOTICE]: (state, { payload: form }) => ({
    ...state,
    [form]: initialState[form],
  }),
  [ACTION_GET_NOTICE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    notice: data,
  }),
  [ACTION_SET_NOTICE_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    noticeSuccess: data === 200,
  }),
}, [initialState]);