import React from 'react';
import styled from 'styled-components'

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 9998;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  img {
    min-width: 380px;
    max-width: 380px;
  }
`;

const Modal = props => {
  return (
    <>
      <ModalOverlay visible={props.visible} />
      <ModalWrapper tabIndex="-1" visible={props.visible}>
        <ModalInner tabIndex="0" className="modal-inner">
          {props.children}
        </ModalInner>
      </ModalWrapper>
    </>
  )
}


export default Modal