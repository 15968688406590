import { callApi } from './index';
import { GET_MEMBER_TYPE, GET_MEMBERSEQ, GET_USER_INFO } from '../utils/UseFunc/UseStorage';
import i18next from 'i18next';

const Path = '/mission';

export const getMission = () => {
  return callApi().get(Path + '/listMission?page=0&size=10000&memberSeq=' + GET_MEMBERSEQ())
    .then(res => {
      res.data.list.forEach((value, index) => {
        res.data.list[index].missionSeq = index + '/' + value.missionSeq;
      })

      return res;
    });
};

export const getMissionNow = page => {
  return callApi().get(Path + '/listMission?size=1&page=' + page + '&memberSeq=' + (GET_USER_INFO() ? GET_USER_INFO().mSeq : GET_MEMBERSEQ()))
}

export const getMissionDownloadList = form => {
  return callApi().get(`${Path}/listDownload?strDte=${Date.parse(form.strDte)}&endDte=${Date.parse(form.endDte)}&nIndex=${form.noteSeq}&all=${form.allChk}`);
}

export const setMission = form => {
  let date = form.msnDate;
  // 예약 시간이 현재 시간보다 전이면 즉시 전송으로 변경
  if(new Date(date) < new Date()) {
    date = new Date();
  }

  const noteSeq = [], rptSeq = [];
  let type = 'INDIVIDUAL';

  form.msnCtg.forEach(value => {
    noteSeq.push(value.seq);
  });

  form.msnRpt.forEach(value => {
    if (value.seq === 0) {
      type = 'ALL';
    } else {
      rptSeq.push(value.seq);
    }
  });

  return callApi().post(Path + '/sendMission', {
    title: form.msnTitle,
    contents: form.msnCnt,
    missionNoteSeq: noteSeq,
    missionType: type,
    takerSeq: rptSeq,
    startDate: Date.parse(date)
  }).then(res => {
    if (form.urlLogo && form.urlLogo.type === 'image/jpeg') {
      const formData = new FormData();
      formData.append('img', form.urlLogo);

      return callApi().post(Path + '/img?missionSeq=' + res.data.missionSeq, formData);
    }

    return res;
  });
};

export const getRptList = async name => {
  const list = [];

  if(GET_MEMBER_TYPE() === 'S') {

    return await callApi().get(`/company/listManager?page=0&size=10000${name ? '&name=' + name : ''}`)
      .then(res => {
        res.data.list.forEach(value => {
          value.name && list.push({ name: i18next.t('AllMemberListForManager', { name: value.name }), seq: value.memberSeq, type: 'M', check: false });
        });

        return callApi().get(`/company/listUser?page=0&size=10000${name ? '&name=' + name : ''}`)
      }).then(res => {
        res.data.list.forEach(value => {
          value.name && list.push({ name: value.name, seq: value.memberSeq, type: 'U', check: false });
        });

        res.data = list;

        return res;
      });

  } else {
    callApi().get(`/company/listUser?page=0&size=10000${name ? '&name=' + name : ''}`)
      .then(res => {
        res.data.list.forEach(value => {
          value.name && list.push({ name: value.name, seq: value.memberSeq, type: 'U', check: false });
        });
      });

    return {
      data: list
    };
  }
}

export const getRptAchieveList = seq => {
  return callApi().get('/manager/missionAnalysis?mIndex=' + seq);
}

export const getTopic = () => {
  return callApi().get(Path + '/mapCat');
}

export const setTopic = form => {
  return callApi().post(Path + '/addCat', {
    parentSeq: 0,
    title: form,
  });
};

export const getNote = title => {
  return callApi().get(Path + '/listNote')
    .then(res => {
      const data = [];

      res.data.list.forEach(value => {
        if(title) {
          value.secondCat === title && data.push(value);
        } else {
          data.push(value)
        }
      });

      res.data = data;

      return res;
    });
};

export const setNote = form => {
  const { note, seq } = form;

  return callApi().post(Path + '/addNote', {
    missionCatSeq: seq,
    note,
  });
};

export const deleteNote = seq => {
  return callApi().delete(Path + '/deleteNote', {
    data: {
      list: [seq]
    },
  });
}

export const getmissionComment = seq => callApi().get(`${Path}/comment?missionSeq=${seq}&page=0&size=10000`);
export const setMissionComment = form => {
  const formData = new FormData();
  for(let i = 0; i < form.comment.img.length; i++) {
    formData.append('img', form.comment.img[i]);
  }

  formData.append('missionSeq', Number(form.seq));
  formData.append('memberSeq', GET_MEMBERSEQ());
  formData.append('text', form.comment.contents);

  if(form.comment.parents)
    formData.append('parent', form.comment.parents)

  return callApi().post(`${Path}/photoComment`, formData)
    .then(res => {
    res.data.reComment = !!form.comment.parents
    res.data.status = res.status

    return res;
  });
};

export const deleteMissionComment = seq => {
  return callApi().delete(Path + '/deleteComment', {
    data: {
      commentSeq: seq
    },
  });
}
