import React from 'react';
import styled from 'styled-components'
import { AiOutlineQuestion, AiOutlineWarning } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr'
import { FcCheckmark } from 'react-icons/fc'
import { DftColor, DngColor } from '../../lib/styles/palette';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_ALERT } from '../../modules/common/AlertModule';
import Button from './Button';
import i18next from 'i18next';
import {withRouter} from 'react-router-dom';
import { RiFileExcel2Line } from 'react-icons/all';

const AlertWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const AlertOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const AlertInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: 400px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  animation: menu-fade-in 0.4s ease 1, menu-move-down 0.3s ease-out 1;

  img {
    min-width: 380px;
    max-width: 380px;
  }
`;

const AlertHeader = styled.div`
  padding: 15px;
  border-bottom: 1px solid #949494;
  color: #FFF;

  &.Warning {background: linear-gradient(45deg, ${DngColor}, #ee4e4e)}
  &.Success, &.EXCEL {background: linear-gradient(45deg, ${DftColor}, #8ac2ce)}

  span {
    position: absolute;
    right: 12px;
    top: 13px;
    color: #FFF;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
    stroke: #FFF;
    }
  }
`;

const AlertBody = styled.div`
  padding: 1.5rem 2rem;
  text-align: center;

  svg {
    width: 60px;
    height: 60px;
  }

  p {
    margin : 2rem 1rem 1rem 1rem;
    font-size: 14px;
  }

  span {
    font-size: 13px;
    color: rgb(97, 97, 97);
  }
`;

const AlertButton = styled.div`
  padding-top: 1rem;

  button {
    width: 35%;
    height: 30px;
    padding: 0;
    font-size: 0.875rem;
  }
`;

const Alert = () => {
  const dispatch = useDispatch();
  const { alertConfig } = useSelector(({ alert }) => ({
    alertConfig: alert.alertConfig,
  }));

  const onClose = () => {
    dispatch(RESET_ALERT());

    if(alertConfig.reload) {
      window.location.reload();
    }
  };

  const onSubmit = () => {
    if (alertConfig.submit.fnc) {
      dispatch(alertConfig.submit.fnc);
    }

    if(alertConfig.submit.act) {
      alertConfig.submit.act();
    }

    dispatch(RESET_ALERT());
  };

  return (
    <>
      <AlertOverlay visible={alertConfig.visible}/>
      <AlertWrapper tabIndex="-1" visible={alertConfig.visible}>
        <AlertInner tabIndex="0" className="modal-inner">
          <AlertHeader className={'txtC ' + alertConfig.type}>
            {alertConfig.header || i18next.t('Notification')}

            <span onClick={() => onClose()}>
              <GrClose color="#FFF"/>
            </span>
          </AlertHeader>

          <AlertBody>
            {alertConfig.type === 'Warning'
              ? <AiOutlineWarning color={DngColor}/>
              : alertConfig.type === 'Success' ? <FcCheckmark/>
              : alertConfig.type === 'EXCEL' ? <RiFileExcel2Line color={DftColor}/> : ''}
            <p>
              {alertConfig.text}
            </p>

            <span>{alertConfig.subText}</span>

            {alertConfig.check ?
              <AlertButton className="mt-3">
                <Button
                  background={alertConfig.type === 'Warning' ? DngColor : DftColor}
                  Text={i18next.t('ButtonConfirm')}
                  onClick={() => onSubmit()}
                />

                <Button
                  background={'#ccc'}
                  Text={i18next.t('ButtonClose')}
                  onClick={() => onClose()}
                  className="ml-2"
                />
              </AlertButton>
              :
              <AlertButton className="mt-3">
                <Button
                  background={alertConfig.type === 'Warning' ? DngColor : DftColor}
                  Text={i18next.t('ButtonConfirm')}
                  onClick={() => onClose()}
                />
              </AlertButton>
            }
          </AlertBody>
        </AlertInner>
      </AlertWrapper>
    </>
  );
}


export default withRouter(Alert)
